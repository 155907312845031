<template>
  <div>
    <!-- <a-form
      class="custom-compact-form"
      :form="form"
      :label-col="{ span: 1 }"
      :wrapper-col="{ span: 20 }"
    >

      <a-form-item>
        <wang-editor-frequently-asked-question
          v-decorator="['content', {
            rules: [
              { max: 2000, message: '最多2000个字符' },
            ]
          }]"
        />
      </a-form-item>
    </a-form> -->
    <p v-html="extraContent" />
  </div>
</template>

<script>
import WangEditorFrequentlyAskedQuestion from '@/components/Editor/WangEditor'
import { findExtraContentInfoAndBuddhistNotesInfo } from '@/api/order'

export default {
  name: 'IndexFrequentlyAskedQuestion',
  components: {
    WangEditorFrequentlyAskedQuestion
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'frequently_asked_question_edit' }),
      submitting: false,
      extraContent: ''
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      // 访问编辑框的时候加载以前保存的内容
      findExtraContentInfoAndBuddhistNotesInfo(this.id).then((res) => {
        // this.form.setFieldsValue({
        //   content: res.data.extra_content
        // })
        this.extraContent = res.data.extra_content
      })
    }
  }
}
</script>

<style scoped>
.set-button-center {
  text-align: center;
}
</style>
