<template>
  <div>
    <p v-html="buddhistNotes" />
  </div>
</template>

<script>
import WangEditorFrequentlyAskedQuestion from '@/components/Editor/WangEditor'
import { findExtraContentInfoAndBuddhistNotesInfo } from '@/api/order'

export default {
  name: 'IndexFrequentlyAskedQuestion',
  components: {
    WangEditorFrequentlyAskedQuestion
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'frequently_asked_question_edit' }),
      submitting: false,
      buddhistNotes: ''
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      // 访问编辑框的时候加载以前保存的内容
      findExtraContentInfoAndBuddhistNotesInfo(this.id).then((res) => {
        // this.form.setFieldsValue({
        //   content: res.data.buddhist_notes
        // })
        this.buddhistNotes = res.data.buddhist_notes
      })
    }
  }
}
</script>

<style scoped>
.set-button-center {
  text-align: center;
}
</style>
